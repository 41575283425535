@font-face {
    font-family: Roboto;
    src: url('./assets/fonts/Roboto-Regular.ttf');
}

@font-face {
    font-family: RobotoBold;
    src: url('./assets/fonts/Roboto-Bold.ttf');
    font-weight: bold;
}

body {
    font-family: 'Roboto';
    background-color: white;
}

a {
    text-decoration: none;
    color: black;
}

.App {
    margin-top: -20px !important;
    position: relative;
    display: grid;
    grid-template-columns: repeat(12, 8%);
    column-gap: 0.3%;
    max-width: 1280px;
    margin: auto;
    padding: 0 60px 0 60px;
    /* z-index: 2; */
}

.app-container {
    height: 100%
}

.paramButtons {
    margin-left: 10px;
}

.calculationName {
    display: flex;
    align-items: baseline;
}

.calculationName input {
    font-size: 30px;
    height: unset;
    margin-left: 10px;
}

.calculationName label {
    font-size: 30px;
    height: unset;
    padding-left: 10px;
}


.calculationDate {
    display: flex;
}

.calculationDate label {
    padding-left: 10px;
}
.projectNavigation {
    display: flex;
    align-items: baseline;
    height: 45px;
    background-color: #D9D9D9;
    max-width: 1280px;
    margin: auto;
    padding: 0 60px 0 60px;
    justify-content: space-between;
}

.projectNavigation-background {
    grid-column: 1/13;
    background-color: #D9D9D9;
    width: 100%;
}

.nav_params {
    display: flex;
}

.nav_buttons button{
    font-size: 17px;
    margin: 12px 5px 0px 0px;   
}

.calculatorContainer {
    grid-column: 1/5;
    background: black;
    text-align: left;
    font-size: 14px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    
}

.calculatorContent {
    width: 100%;
    display: grid;
    grid-template-columns: 50% auto;
}

.devicesDiv {
    padding-left: 10px;
    padding-top: 30px;
}
.labelFull {
    grid-column: 1/3;
    text-align: left;
    /* font: normal normal normal 20px/24px Exo 2; */
    font-size: 20px;
    letter-spacing: 0px;
    color: #91D9B1;
    opacity: 1;
    margin-top: 30px;
    padding-left: 10px;
}

.label_notop {
    margin-top: 15px;
}

.calcParam {
    margin-left: 10px;
}


.labelSection {
    font-size: 14px;
    color: #91D9B1;
    padding: 2px;
    border-bottom: 1px solid #91D9B1;
}

.labelSection-inactive:hover {
    color: #91D9B1;
    border-bottom: 1px solid #91D9B1;
}

.labelSection-inactive {
    color: #959595;
    border-bottom: none;
}

/* .labelInput {
    font-size: 14px;
} */

.param label {
    font-size: 16px;
    /* display: block; */
}

.param {
    padding-top: 10px;
    padding-right: 20px;
}

.param1 {
    width: 200px;
}

.param .label {
    font-family: 'Roboto';
    font-size: 14px;
    padding-right: 10px;
}

.param .labelBold {
    font-family: 'RobotoBold';
    font-size: 20px;
}

.param button {
    width: 100%;
    background: #91D9B1 0% 0% no-repeat padding-box;
    box-shadow: -1px 2px 5px #0000000B;
    border-radius: 2px;
    opacity: 1;
    color: #1f1f1f;
    text-align: center;
    height: 35px;
    font-family: 'RobotoBold';
    font-size: 16px;
    padding: 5px 30px;
    border: none;
}

.calcFooter {
    grid-column: 1/3;
    margin-left: 10px;
}

.calculate {
    grid-column: 1/3;
    margin-top: 30px;
    padding-left: 10px;
}

.clear {
    margin-left: 10px;
    margin-top: 25px;
}

.input label, .dropDown label {
    display: block;
    margin-bottom: 5px;
    margin-top: 10px;
}

.input .label-inactive {
    color: #959595;
}

.dropDown .label-inactive {
    color: #959595;
}

.content-inactive {
    opacity: 0.5;
}
.calculate button {
    /* width: 100%; */
    background: #f5e1e1 0% 0% no-repeat padding-box;
    box-shadow: -1px 2px 5px #0000000B;
    border-radius: 2px;
    opacity: 1;
    color: #1f1f1f;
    text-transform: uppercase;
    text-align: center;
    height: 35px;
    font-family: 'RobotoBold';
    width: 70%;
    border: none;
    cursor: pointer;
}

.calculate button:hover {
    background: #91D9B1 0% 0% no-repeat padding-box;
    
}

.clear button {
    width: 100%;
    background: black 0% 0% no-repeat padding-box;
    /* background: #91D9B1 0% 0% no-repeat padding-box; */
    border: 1px solid #f5e1e1;
    box-shadow: -1px 2px 5px #0000000B;
    border-radius: 2px;
    opacity: 1;
    color: #f5e1e1;
    /* color: #1f1f1f; */
    text-transform: uppercase;
    text-align: center;
    height: 35px;
    font-family: 'Roboto';
    width: 70%;
    /* border: none; */
    cursor: pointer;
}

.clear button:hover {
    color: #91D9B1;
    border: 1px solid #91D9B1;
}

.resultDescription {
    padding: 20px 0px 0px 10px;
    font-size: 20px;
    color: white;
}

.result_value {
    color: #91d9b1;
}

.resultSpacing {
    padding: 5px 0px 5px 0px;
}

.result {
    grid-column: 5/13;
    /* width: 100%;
    display: grid;
    grid-template-columns: 33% 33% auto; */
    
}

.resultNavigation {
    margin: 10px;
}


.resultNavigation-name {
    margin-bottom: 10px;
}
.resultNavigation-param {
    /* width: 100px; */
    text-align: left;
    display: flex;
    justify-content: space-between;
}

.resultNavigation-param button {
    margin-left: 2px;
    margin-right: 2px;
}

.generateReport {
    display: inline;
}
.generateReport label {
    margin-left: 5px;
}
/* .label {
    margin-right: 30px;
} */

.labelBig {
    /* text-align: right; */
    font-size: 20px;
    letter-spacing: 0px;
    color: #1F1F1F;
    opacity: 1;
}

/* .input {
    display: block;
    width: 150px;
} */



.result-link {
    cursor: pointer;
    padding: 5px;
    border: 1px solid black;
}

.result-link__active {
    text-decoration: underline;
}

.result-component {
    grid-column: 2/4;
    text-align: left;
    padding-left: 30px;;
    /* border: 1px solid black; */
    width: 100%;
}

.header-gradient {
    background: transparent linear-gradient(270deg, #0382B1 0%, #0098D0 100%) 0% 0% no-repeat padding-box;
    /* z-index: 2; */
}
.header {
    display: grid;
    grid-template-columns: 30% auto;
    max-width: 1280px;
    margin: auto;
    padding-left: 60px;
    padding-right: 60px;
    /* z-index: 2; */
}

.pageContent {
    width: 1280px;
    margin: auto;
}

.calculator {
    padding-top: 30px;
    padding-left: 10px;
}

.header-name {
    font-size: 20px;
    padding: 10px;
    font-weight: bold;
}

nav {
    text-align: right;
    padding: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.navLink {
    padding: 5px 16px 5px 16px;
    text-decoration: none;
    color: #ffffff;
    letter-spacing: 0.8px;
}

.navLink div {
    display: inline-block;
}

.navLink-selected {
    /* font-weight: bold; */
    color: #f5f5f5;
    letter-spacing: 0.8px;
}

.navLink-selected div {
    border-bottom: 1px solid #ffffff;
}

/* .navLink:hover {
    text-decoration: underline;
} */

.canvas {
    /* width: 1000px;
    height: 1200px; */
    /* border: 1px solid black; */
}

.accountForm label {
    width: 150px;
    display: inline-block;
    text-align: right;
    padding-right: 5px;
}

.accountForm .labelBold {
    font-size: 17px!important;
}

.accountContainer-content {
    width: 500px!important;
}

.loginContainer {
    width: 50%;
    margin-left: 50%;
    padding: auto;
}

.loginContainer-content {
    margin-left: 120px;
    padding-top: 60px;
    width: 370px;
}

.loginContainer-content a{
    text-align: left;
    font-size: 24px;
    letter-spacing: 0px;
    color: #f5e1e1;
    opacity: 1;
    display: block;
    text-transform: capitalize;
    cursor: pointer;
}

.loginContainer-content .active {
    color: #FFFFFF;
    /* text-decoration: underline;     */
    border-bottom: 2px solid #FFFFFF;
}

.loginContainer-content .login {
    float: left;
}

.loginContainer-content .register {
    float: right   ;
}

.loginContainer-content .inputs {
    float: right   ;
    width: 100%;
}

.loginContainer-content input {
    width: 100%;
    text-align: left;
    margin-top: 5px;
}

.loginContainer-content div {
    margin-top: 10px;
}

.loginContainer-content label {
    font-family: 'Roboto';
    width: 100%;
    text-align: left;
    font-size: 14px;
    letter-spacing: 0px;
    color: #D9D9D9;
    opacity: 1;
}

.loginContainer-content button {
    width: 100%;
    background: #91D9B1 0% 0% no-repeat padding-box;
    box-shadow: -1px 2px 5px #0000000b;
    border-radius: 2px;
    opacity: 1;
    color: #1f1f1f;
    text-transform: uppercase;
    text-align: center;
    height: 35px;
    font-family: 'RobotoBold';
    font-size: 16px;
    border: none;
    cursor: pointer;
    margin-top: 40px;
}

.loginContainer-content button a {
    color: inherit;
    text-align: inherit;
    font-size: inherit;

}

.accountSection {
    margin: 20px;
}

.accountSection .paramLabel {
    width: 120px;
    text-align: right;
    padding-right: 10px;
}
.sectionTitle {
    text-align: left;
    /* font: normal normal normal 20px/24px Exo 2; */
    font-size: 20px;
    letter-spacing: 0px;
    color: #91D9B1;
    opacity: 1;
    margin-top: 30px;
    /* padding-left: 10px; */
    

}

.accountParam {
    display: flex;
    align-items: center;
}

.accountParam .labelBold {
    letter-spacing: 0.8px;
    height: 22px;
}

.accountParam input {
    margin-top: 0px;
}
 
.image {
    width: 300px;
}

.mainInfo {
    width: 70%;
    margin: auto;
    display: grid;
    grid-template-columns: 50% auto;
}

.labelBold {
    font-weight: bold;
    padding-right: 10px;
    display: inline-block;
    width: 170px;
}

.projectList-header {
    text-align: left;
    font-size: 30px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    padding-top: 20px;
    padding-left: 60px;
    padding-bottom: 65px;
    display: block;
}

.calculator-header {
    text-align: left;
    font-size: 30px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    padding-top: 20px;
    padding-left: 10px;
    display: block;
}

.projectsList-table {
    border-collapse: collapse;
    color: white;
    border: 3px solid #959595;
    /* border-bottom: 0px; */
    text-align: center;
    width: 100%;
}

.projectsList-table tr{
    border-collapse: collapse;
    border: 2px solid #959595;
    border-bottom: 0px;
}

.projectsList-table .th{
    /* font-weight: bold; */
    color: #91D9B1;
    border: 2px solid #959595;
    /* border-bottom: 0px; */
}

.projectList-head .th {
    color: white !important;
}
.projectsList-table td {
    border-collapse: collapse;
    border: 2px solid #959595;
    border-bottom: 0px;
}

.projectsList-table .fixedWidth{
    width: 100px;
    min-width: 100px;
}

.projectsList-table .checkbox {
    width: 30px;
    min-width: 30px;
}

.projectsList-table .projectName {
    width: 150px;
    min-width: 150px;
    text-align: left;
    padding: 5px;
    box-sizing: border-box;
    /* display: flex; */
}

.projectsList-table .projectName .name {
    display: inline-block;
    width: 110px;
}

.projectsList-table .projectName .plus {
    font-size: 30px;
}

.projectsList-table .projectName .addCalc {
    color: #959595;
    cursor: pointer;    
    padding-left: 40px;
    /* text-transform: uppercase; */
    font-weight: bold;
}

.projectsList-table .projectName .addCalc:hover {
    color: #91D9B1;
}
.projectList-head  table, tr{
    border: 3px solid #959595;
    border-bottom: 0px;

}

.projectList-head  td {
    border: 2px solid #959595;
    border-bottom: 0px;
}

.reportContainer .projectsList-table{
    color: black !important;
}
.hidden {
    display: none;
}

/* .visible table{
    background-color: #aaaaaa;
} */

.projectList {
    margin: 0px;
    padding-left: 60px;
    width: 40%;
}

.projectList li{
    list-style-type: none;
}

.clickable {
    cursor: pointer;
}

.underlineHover:hover {
    text-decoration: underline;
}

/* .clickable table:hover {
    background-color: #aaaaaa;
} */

/* dropdown menu */
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown-content {
    display: none;
    position: absolute;
    min-width: 160px;
    width: 100%;
    z-index: 1;
    background-color: #0382B1;
    padding: 8px 0px 10px 0px;
}

.dropdown-content .navLink {
    display: block;
    background-color: #0382B1 ;
    text-align: left;
}

input, select {
    width: 150px;
    box-sizing: border-box;
    height: 22px;
}

input[type="checkbox"] {
    width: 20px;
}

.reportContainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 16%) auto;
    margin-top: 20px;
    overflow-y: scroll;
    height: 80%;
}

.reportContainer .leftColumn {
    grid-column: 1/4;
    text-align: left;
    padding-left: 20px;
}

.reportContainer .rightColumn {
    grid-column: 4/7;
    text-align: right;
    padding-right: 20px;
}

.reportContainer .full {
    grid-column: 1/7;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
}

.reportContainer .column1 {
    grid-column: 1/3;
    text-align: center;
}

.reportContainer .column2 {
    grid-column: 3/5;
    text-align: center;
}

.reportContainer .column3 {
    grid-column: 5/7;
    text-align: center;
}

.reportContainer input {
    width: 250px;
}

.marginTop {
    margin-top: 50px !important;
}

.centerPosition {
    margin: auto;
}

.leftPosition {
    text-align: left !important;
}

.popupDesc {
    margin-right: 30px;

}

.area {
    width: 100%;
    height: 50px;
}

.closeButton {
    position: absolute;
    top: 21px;
    right: 21px;
}

.dropDown {
    /* padding-left: 10px; */
}
.dropDown label {
    display: block;
    margin-bottom: 5px;
}

.buttonSection {
    margin: 20px;
    margin-left: 0px;
    display: flex;
    gap: 10px;
    padding-left: 60px;
    width: 608px;
    justify-content: space-between;
}

.buttonSection label {
    text-align: left;
    font-size: 14px;
    letter-spacing: 0px;
    color: #91D9B1;
    opacity: 1;
    display: block;
    padding-bottom: 2px;
}

.buttonSection button {
    /* width: 100%; */
    border:1px solid #959595;
    background-color: inherit;
    box-shadow: -1px 2px 5px #0000000B;
    border-radius: 2px;
    opacity: 1;
    color: #959595;
    text-align: center;
    height: 35px;
    font-family: 'RobotoBold';
    font-size: 16px;
    padding: 5px 30px;
    text-transform: uppercase;
    margin-top: 7px;
    cursor: pointer;
    /* border: none; */
}

.buttonSection button:hover {
    color: #8ADBB5;
    border-color: #8ADBB5;
}

.buttonSection .inactive {
    opacity: 0.4;
    cursor: default!important;
}

.buttonSection .inactive:hover {
    color: #959595!important;
    border-color: #959595!important;
}

.main-background {
    background-color: red;
}

.background-calc {
    background: linear-gradient(90deg, black 33%, white 0%);

}   

.background-calcList {
    height: 83vh;
    overflow-y: auto;
    min-height: 500px;
    background: url("./assets/img/bg.png") no-repeat right top, linear-gradient(90deg, black 70%, white 0%);

}   
.background-login {
    height: 100vh;
    min-height: 900px;
    background: url("./assets/img/bg.png") no-repeat left top, linear-gradient(90deg, white 40%, black 0%);

}

.background-account {
    height: 100vh;
    min-height: 1000px;
    background: url("./assets/img/bg.png") no-repeat right top, linear-gradient(90deg, black 55%, white 0%);

}

.modalHeader {
    text-align: center;
    margin-bottom: 20px;
    font-size: 18px;
}

.modalHeader .labelBold {
    width: 100%;
}

.modalLabel {
    width: 100%;
    display: block;
    text-align: center;
    padding-top: 20px;
}

.modalButton {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.modalButton button {
    margin: 2px;
}

.footer {
    width:100%;
    background: #EDF0F4 0% 0% no-repeat padding-box;
    height: 70px;
    display: flex; 
    align-items: center;
    justify-content: space-around;
}

.footer img {
    height: 35px;
    margin: 10px;
}

.buttonAdd { 
    border: none;
    background-color: black;
    color: #8ADBB5;
    font-size: 30px;
}

.cookiesBanner {
    position: fixed;
    bottom: 0px;
    width: 100%;
    background-color: rgba(19, 19, 19, 0.8);
    height: 50px;
    display: flex;
    justify-content: space-between;
    font-family: 'Roboto';
    color: #999;
}

.cookiesBanner .content {
    text-align: center;
    margin-left: 20px;
    padding-top: 15px;
    
}

.cookiesBanner .close {
    margin-right: 20px;
    padding-top: 15px;
    cursor: pointer;
}

.hidden {
    display: none;
}